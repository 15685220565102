import React , {useState} from 'react'
import ArrowGif from '../assets/image/arrow-animation.gif'
import PosterImage from '../assets/image/video-image.jpg'
import WorldImage from '../assets/image/world-image.png'


const Video = () => {
  const [openTab, setOpenTab] = useState(null);

  const toggleTab = (tabIndex) => {
    setOpenTab(openTab === tabIndex ? null : tabIndex);
  };

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validate = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = 'نام و نام خانوادگی الزامی است.';
    }
    if (!phone) {
      newErrors.phone = 'شماره تلفن الزامی است.';
    } else if (!/^\d+$/.test(phone)) {
      newErrors.phone = 'شماره تلفن باید تنها شامل اعداد باشد.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // برمی‌گرداند true اگر خطایی وجود نداشته باشد
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return; // اگر اعتبارسنجی ناموفق بود، ارسال متوقف می‌شود

    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch('/ez/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          input_1: name,
          input_4: phone,
          is_submit_19: 1,
          gform_submit: 19,
          gform_unique_id: '',
          state_19: 'WyJbXSIsIjY1ODYwZGZlNmU4ZjMzZWQ0ZTExYTM5NDdhMzM1ZmE1Il0=',
          gform_target_page_number_19: 0,
          gform_source_page_number_19: 1,
          gform_field_values: '',
        }),
      });

      if (response.ok) {
        setSuccessMessage('درخواست با موفقیت ارسال شد.');
        setName('');
        setPhone('');
      } else {
        throw new Error('خطا در ارسال درخواست.');
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div
  className="xclf xclf-b91ec14 e-flex e-con-boxed e-con e-parent"
  cvc="b91ec14"
  element-kk="container"
  sssssss='{"background_background":"classic"}'
>
  <div className="e-con-inner">

    <div
      className="xclf xclf-ede4c4e e-con-full e-flex e-con e-child"
      cvc="ede4c4e"
      element-kk="container"
    >
      <div
        className="xclf xclf-4fa5136 e-con-full e-flex e-con e-child"
        cvc="4fa5136"
        element-kk="container"
        sssssss='{"background_background":"classic"}'
      >
        <div
          className="xclf xclf-7c77620 e-flex e-con-boxed e-con e-child"
          cvc="7c77620"
          element-kk="container"
        >
          <div className="e-con-inner new-settings">
            <div
              className="xclf xclf-c3fd3de e-con-full e-flex e-con e-child"
              cvc="c3fd3de"
              element-kk="container"
            >
              <div
                className="xclf xclf-d68a4ad ggeexx-widget ggeexx-widget-image"
                cvc="d68a4ad"
                element-kk="widget"
                data-widget_type="image.default"
              >
                <div className="ggeexx-widget-container">
                  <img
                    
                    src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs="
                    decoding="async"
                    width={200}
                    height={200}
                    data-src={ArrowGif}
                    className="lazyload"
                    alt=""
                  />
                  <noscript>
                    &lt;img decoding="async" width="200" height="200"
                    src={ArrowGif}
                    class="lazyload" alt=""
                    /&gt;
                  </noscript>
                </div>
              </div>
            </div>
            <div
              className="xclf xclf-3389044 e-con-full e-flex e-con e-child"
              cvc={3389044}
              element-kk="container"
            >
              <div
                className="xclf xclf-62726b0 ggeexx-widget ggeexx-widget-image"
                cvc="62726b0"
                element-kk="widget"
                data-widget_type="image.default"
              >
                <div className="ggeexx-widget-container">
                  <img
                    
                    src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs="
                    decoding="async"
                    width={200}
                    height={200}
                    data-src={ArrowGif}
                    className="lazyload"
                    alt=""
                  />
                  <noscript>
                    &lt;img decoding="async" width="200" height="200"
                    src={ArrowGif}
                    class="lazyload" alt=""
                    /&gt;
                  </noscript>
                </div>
              </div>
            </div>
            <div
              className="xclf xclf-80c94da e-con-full e-flex e-con e-child"
              cvc="80c94da"
              element-kk="container"
            >
              <div
                className="xclf xclf-7fe1573 ggeexx-widget ggeexx-widget-image"
                cvc="7fe1573"
                element-kk="widget"
                data-widget_type="image.default"
              >
                <div className="ggeexx-widget-container">
                  <img
                    
                    src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs="
                    decoding="async"
                    width={200}
                    height={200}
                    data-src={ArrowGif}
                    className="lazyload"
                    alt=""
                  />
                  <noscript>
                    &lt;img decoding="async" width="200" height="200"
                    src={ArrowGif}
                    class="lazyload" alt=""
                    /&gt;
                  </noscript>
                </div>
              </div>
            </div>
            <div
              className="xclf xclf-11a041d e-con-full e-flex e-con e-child"
              cvc="11a041d"
              element-kk="container"
            >
              <div
                className="xclf xclf-eb79bd0 ggeexx-widget ggeexx-widget-image"
                cvc="eb79bd0"
                element-kk="widget"
                data-widget_type="image.default"
              >
                <div className="ggeexx-widget-container">
                  <img
                    
                    src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs="
                    decoding="async"
                    width={200}
                    height={200}
                    data-src={ArrowGif}
                    className="lazyload"
                    alt=""
                  />
                  <noscript>
                    &lt;img decoding="async" width="200" height="200"
                    src={ArrowGif}
                    class="lazyload" alt=""
                    /&gt;
                  </noscript>
                </div>
              </div>
            </div>
            <div
        className="xclf xclf-0bdf63f e-con-full e-flex e-con e-child"
        cvc="0bdf63f"
        element-kk="container"
      >
        <div
          className="xclf xclf-106a5b2 ggeexx-widget ggeexx-widget-image"
          cvc="106a5b2"
          element-kk="widget"
          data-widget_type="image.default"
        >
          <div className="ggeexx-widget-container">
            <img
              width={1024}
              height={497}
              className="lazyload"
              data-src={WorldImage}
              alt=""
            />
          </div>
        </div>
      </div>
          </div>
        </div>
        <div
          className="xclf xclf-c6a92e7 e-flex e-con-boxed e-con e-child"
          cvc="c6a92e7"
          element-kk="container"
          sssssss='{"background_background":"classic"}'
        >
          {/** اینجا تصویر باید قرار بگیره */}
        </div>
      </div>
      <div
        className="xclf xclf-9c4ff5b e-con-full e-flex e-con e-child"
        cvc="9c4ff5b"
        element-kk="container"
      >
        <div
          className="xclf xclf-4e57446 e-con-full e-flex e-con e-child"
          cvc="4e57446"
          element-kk="container"
        >
          <div
            className="xclf xclf-d10f600 e-con-full e-flex e-con e-child"
            cvc="d10f600"
            element-kk="container"
            sssssss='{"background_background":"classic"}'
          >
            <div
              className="xclf xclf-64a0aff ggeexx-view-default ggeexx-position-top ggeexx-mobile-position-top ggeexx-widget ggeexx-widget-icon-box"
              cvc="64a0aff"
              element-kk="widget"
              data-widget_type="icon-box.default"
            >
              <div className="ggeexx-widget-container">
                <div className="ggeexx-icon-box-wrapper">
                  <div className="ggeexx-icon-box-icon">
                    <span className="ggeexx-icon ggeexx-animation-">
                      <i aria-hidden="true" className="fas fa-truck-loading" />{" "}
                    </span>
                  </div>
                  <div className="ggeexx-icon-box-content">
                    <h3 className="ggeexx-icon-box-title">
                      <span>ارسال رایگان </span>
                    </h3>
                    <p className="ggeexx-icon-box-description">
                      به سرار نقاط کشور
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="xclf xclf-51b473c e-con-full e-flex e-con e-child"
            cvc="51b473c"
            element-kk="container"
            sssssss='{"background_background":"classic"}'
          >
            <div
              className="xclf xclf-1946425 ggeexx-view-default ggeexx-position-top ggeexx-mobile-position-top ggeexx-widget ggeexx-widget-icon-box"
              cvc={1946425}
              element-kk="widget"
              data-widget_type="icon-box.default"
            >
              <div className="ggeexx-widget-container">
                <div className="ggeexx-icon-box-wrapper">
                  <div className="ggeexx-icon-box-icon">
                    <span className="ggeexx-icon ggeexx-animation-">
                      <i aria-hidden="true" className="fas fa-vote-yea" />{" "}
                    </span>
                  </div>
                  <div className="ggeexx-icon-box-content">
                    <h3 className="ggeexx-icon-box-title">
                      <span>پرداخت درب محل </span>
                    </h3>
                    <p className="ggeexx-icon-box-description">
                      در سرار نقاط کشور
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="xclf xclf-649b845 e-flex e-con-boxed e-con e-child"
          cvc="649b845"
          element-kk="container"
        >
          <div className="e-con-inner">
            <div
              className="xclf xclf-112b60d e-con-full e-flex e-con e-child"
              cvc="112b60d"
              element-kk="container"
              sssssss='{"background_background":"classic"}'
            >
              <div
                className="xclf xclf-3972ec9 ggeexx-view-default ggeexx-position-top ggeexx-mobile-position-top ggeexx-widget ggeexx-widget-icon-box"
                cvc="3972ec9"
                element-kk="widget"
                data-widget_type="icon-box.default"
              >
                <div className="ggeexx-widget-container">
                  <div className="ggeexx-icon-box-wrapper">
                    <div className="ggeexx-icon-box-icon">
                      <span className="ggeexx-icon ggeexx-animation-">
                        <i aria-hidden="true" className="fas fa-check-double" />{" "}
                      </span>
                    </div>
                    <div className="ggeexx-icon-box-content">
                      <h3 className="ggeexx-icon-box-title">
                        <span>ضمانت کالا </span>
                      </h3>
                      <p className="ggeexx-icon-box-description">
                        بازگشت وجه تا 7 روز
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="xclf xclf-fae9f7a e-con-full e-flex e-con e-child"
              cvc="fae9f7a"
              element-kk="container"
              sssssss='{"background_background":"classic"}'
            >
              <div
                className="xclf xclf-a3cbeac ggeexx-view-default ggeexx-position-top ggeexx-mobile-position-top ggeexx-widget ggeexx-widget-icon-box"
                cvc="a3cbeac"
                element-kk="widget"
                data-widget_type="icon-box.default"
              >
                <div className="ggeexx-widget-container">
                  <div className="ggeexx-icon-box-wrapper">
                    <div className="ggeexx-icon-box-icon">
                      <span className="ggeexx-icon ggeexx-animation-">
                        <i aria-hidden="true" className="far fa-heart" />{" "}
                      </span>
                    </div>
                    <div className="ggeexx-icon-box-content">
                      <h3 className="ggeexx-icon-box-title">
                        <span>مشاوره رایگان </span>
                      </h3>
                      <p className="ggeexx-icon-box-description">
                        سوالات خود را از ما بپرسید
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="xclf xclf-000ae7b e-con-full e-flex e-con e-child"
      cvc="000ae7b"
      element-kk="container"
    >
      <div
        className="xclf xclf-86a0c15 e-con-full e-flex e-con e-child"
        cvc="86a0c15"
        element-kk="container"
      >
<div className="xclf xclf-9d98743 ggeexx-widget ggeexx-widget-accordion" cvc="9d98743" element-kk="widget" data-widget_type="accordion.default">
      <div className="ggeexx-widget-container">
        <div className="ggeexx-accordion">
          {Array.from({ length: 5 }, (_, index) => (
            <div className="ggeexx-accordion-item" key={index}>
              <div
                id={`ggeexx-tab-title-${index + 1651}`}
                className="ggeexx-tab-title"
                data-tab={index + 1}
                role="button"
                aria-controls={`ggeexx-tab-content-${index + 1651}`}
                aria-expanded={openTab === index + 1651}
                onClick={() => toggleTab(index + 1651)}
                onKeyPress={(e) => e.key === 'Enter' && toggleTab(index + 1651)}
                tabIndex={0}
              >
                <span className="ggeexx-accordion-icon ggeexx-accordion-icon-left" aria-hidden="true">
                  <span className="ggeexx-accordion-icon-closed">
                    <i className="fas fa-caret-down" />
                  </span>
                  <span className="ggeexx-accordion-icon-opened">
                    <i className="fas fa-caret-up" />
                  </span>
                </span>
                <a className="ggeexx-accordion-title" tabIndex={0}>
                  {`سوال ${index + 1}`}
                </a>
              </div>
              <div
                id={`ggeexx-tab-content-${index + 1651}`}
                className={`ggeexx-tab-content ggeexx-clearfix ${openTab === index + 1651 ? 'open' : 'closed'}`}
                data-tab={index + 1}
                role="region"
                aria-labelledby={`ggeexx-tab-title-${index + 1651}`}
                style={{ display: openTab === index + 1651 ? 'block' : 'none' }}
              >
                <p>
                  {`پاسخ به سوال ${index + 1} به این صورت است...`}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
      </div>
      <div
        className="xclf xclf-5da21e9 e-con-full e-flex e-con e-child"
        cvc="5da21e9"
        element-kk="container"
        sssssss='{"background_background":"classic"}'
      >
        <div
          className="xclf xclf-7958e95 ggeexx-widget ggeexx-widget-heading"
          cvc="7958e95"
          element-kk="widget"
          data-widget_type="heading.default"
        >
          <div className="ggeexx-widget-container">
            <h3 className="ggeexx-heading-title ggeexx-size-default">
              اگر تا اینجا سوالی داری شمارتو بزار تا سریع بهت زنگ بزنیم!
            </h3>
          </div>
        </div>
        <div
          className="xclf xclf-20e9b40 ggeexx-widget-divider--view-line_icon ggeexx-view-default ggeexx-widget-divider--element-align-center ggeexx-widget ggeexx-widget-divider"
          cvc="20e9b40"
          element-kk="widget"
          data-widget_type="divider.default"
        >
          <div className="ggeexx-widget-container">
            <div className="ggeexx-divider">
              <span className="ggeexx-divider-separator">
                <div className="ggeexx-icon ggeexx-divider__element">
                  <i aria-hidden="true" className="fas fa-caret-down" />
                </div>
              </span>
            </div>
          </div>
        </div>
        <div
          className="xclf xclf-5063cc1 ggeexx-widget ggeexx-widget-shortcode"
          cvc="5063cc1"
          element-kk="widget"
          data-widget_type="shortcode.default"
        >
          <div className="ggeexx-widget-container">
            <div className="ggeexx-shortcode">
              <div
                className="gf_browser_chrome gform_wrapper gravity-theme gform-theme--no-framework"
                data-form-theme="gravity-theme"
                data-form-index={1}
                id="gform_wrapper_19"
              >
                <div className="gform_heading">
                  <p className="gform_description" />
                  <p className="gform_required_legend">
                    فیلد های "
                    <span className="gfield_required gfield_required_asterisk">
                      *
                    </span>
                    " اجباری هستند
                  </p>
                </div>
                <form
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      noValidate
      id="gform_19"
      action="/ez/"
      data-formid={19}
    >
      <div className="gform-body gform_body">
        <div className="gform_fields top_label form_sublabel_below description_below validation_below" id="gform_fields_19">
          <div className="gfield gfield--type-text gfield--width-half gfield_contains_required" id="field_19_1">
            <label className="gfield_label gform-field-label" htmlFor="input_19_1">
              نام و نام خانوادگی
              <span className="gfield_required">*</span>
            </label>
            <div className="ginput_container ginput_container_text">
              <input
                name="input_1"
                id="input_19_1"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`large ${errors.name ? 'error' : ''}`}
                aria-required="true"
                aria-invalid={!!errors.name}
                autoComplete="name"
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
          </div>
          <div className="gfield gfield--type-text gfield--width-half gfield_contains_required" id="field_19_4">
            <label className="gfield_label gform-field-label" htmlFor="input_19_4">
              شماره تلفن
              <span className="gfield_required">*</span>
            </label>
            <div className="ginput_container ginput_container_text">
              <input
                name="input_4"
                id="input_19_4"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className={`large ${errors.phone ? 'error' : ''}`}
                aria-required="true"
                aria-invalid={!!errors.phone}
                autoComplete="tel"
              />
              {errors.phone && <p className="error-message">{errors.phone}</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="gform_footer top_label">
        <input
          type="submit"
          id="gform_submit_button_19"
          className="gform_button button gform-button--width-full"
          value={isSubmitting ? 'در حال ارسال...' : 'درخواست مشاوره + آموزش رایگان'}
          disabled={isSubmitting}
        />
        <input type="hidden" className="gform_hidden" name="is_submit_19" value={1} />
        <input type="hidden" className="gform_hidden" name="gform_submit" value={19} />
        <input type="hidden" className="gform_hidden" name="gform_unique_id" value="" />
        <input type="hidden" className="gform_hidden" name="state_19" value="WyJbXSIsIjY1ODYwZGZlNmU4ZjMzZWQ0ZTExYTM5NDdhMzM1ZmE1Il0=" />
        <input type="hidden" className="gform_hidden" name="gform_target_page_number_19" id="gform_target_page_number_19" value={0} />
        <input type="hidden" className="gform_hidden" name="gform_source_page_number_19" id="gform_source_page_number_19" value={1} />
        <input type="hidden" name="gform_field_values" value="" />
      </div>
      <p className="akismet-fields-container hidden" data-prefix="ak_">
        <label>
          Δ
          <textarea
            name="ak_hp_textarea"
            cols={45}
            rows={8}
            maxLength={100}
            value=""
          />
        </label>
        <input type="hidden" id="ak_js_2" name="ak_js" value={201} />
      </p>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Video
import React from 'react'
import ArrowGif from '../assets/image/arrow-animation.gif'

const Banner = () => {
  return (
    <div
  className="xclf xclf-318ee9a e-flex e-con-boxed e-con e-parent"
  cvc="318ee9a"
  element-kk="container"
  sssssss='{"background_background":"classic"}'
>
  <div className="e-con-inner">
    <div
      className="xclf xclf-4be4273 e-con-full e-flex e-con e-child"
      cvc="4be4273"
      element-kk="container"
    >
      <div
        className="xclf xclf-fa78e50 e-con-full e-flex e-con e-child"
        cvc="fa78e50"
        element-kk="container"
      >
        <div
          className="xclf xclf-a4b88ea ggeexx-widget ggeexx-widget-heading"
          cvc="a4b88ea"
          element-kk="widget"
          data-widget_type="heading.default"
        >
          <div className="ggeexx-widget-container">
            <span className="ggeexx-heading-title ggeexx-size-default yekanbakh">
              آینده و مسیر زندگیتون و درآمدیتون با یادگیری همین آموزش تغییر
              میکنه
            </span>
          </div>
        </div>
        <div
          className="xclf xclf-dc06f50 ggeexx-widget ggeexx-widget-heading"
          cvc="dc06f50"
          element-kk="widget"
          data-widget_type="heading.default"
        >
          <div className="ggeexx-widget-container">
            <span className="ggeexx-heading-title ggeexx-size-default">
              ما به ساده ترین روش ممکن بهتون آموزش میدیم چطور زبان انگلیسی یاد
              بگیرد
            </span>
          </div>
        </div>
        <div
          className="xclf xclf-e6c2193 e-con-full e-flex e-con e-child"
          cvc="e6c2193"
          element-kk="container"
        >
          <div
            className="xclf xclf-f3b302a e-transform ggeexx-widget ggeexx-widget-image"
            cvc="f3b302a"
            element-kk="widget"
            sssssss='{"_transform_rotateZ_effect":{"unit":"px","size":90,"sizes":[]},"_transform_rotateZ_effect_mobile":{"unit":"deg","size":0,"sizes":[]},"_transform_rotateZ_effect_tablet":{"unit":"deg","size":"","sizes":[]}}'
            data-widget_type="image.default"
          >
            <div className="ggeexx-widget-container">
              <img
                decoding="async"
                width={200}
                height={200}
                data-src={ArrowGif}
                className="lazyload"
                alt=""
              />
            </div>
          </div>
          <div
            className="xclf xclf-a37f282 ggeexx-mobile-align-justify ggeexx-widget-mobile__width-inherit ggeexx-widget ggeexx-widget-button"
            cvc="a37f282"
            element-kk="widget"
            data-widget_type="button.default"
          >
            <div className="ggeexx-widget-container">
              <div className="ggeexx-button-wrapper">
                <a
                  className="ggeexx-button ggeexx-button-link ggeexx-size-sm"
                  href="https://wa.me/989368371221"
                  target="_blank"
                >
                  <span className="ggeexx-button-content-wrapper">
                    <span className="ggeexx-button-icon">
                      <i aria-hidden="true" className="fab fa-whatsapp" />{" "}
                    </span>
                    <span className="ggeexx-button-text">پیام در واتساپ</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="xclf xclf-e388d5a ggeexx-mobile-align-justify ggeexx-widget-mobile__width-inherit ggeexx-widget ggeexx-widget-button"
            cvc="e388d5a"
            element-kk="widget"
            data-widget_type="button.default"
          >
            <div className="ggeexx-widget-container">
              <div className="ggeexx-button-wrapper">
                <a
                  className="ggeexx-button ggeexx-button-link ggeexx-size-sm"
                  href="https://t.me/Acoachgroup"
                  target="_blank"
                >
                  <span className="ggeexx-button-content-wrapper">
                    <span className="ggeexx-button-icon">
                      <i aria-hidden="true" className="fab fa-telegram-plane" />{" "}
                    </span>
                    <span className="ggeexx-button-text">پیام در تلگرام</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="xclf xclf-25eae57 e-transform ggeexx-hidden-tablet ggeexx-hidden-mobile ggeexx-widget ggeexx-widget-image"
            cvc="25eae57"
            element-kk="widget"
            sssssss='{"_transform_rotateZ_effect":{"unit":"px","size":-90,"sizes":[]},"_transform_rotateZ_effect_tablet":{"unit":"deg","size":"","sizes":[]},"_transform_rotateZ_effect_mobile":{"unit":"deg","size":"","sizes":[]}}'
            data-widget_type="image.default"
          >
            <div className="ggeexx-widget-container">
              <img
                
                src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs="
                decoding="async"
                width={200}
                height={200}
                data-src={ArrowGif}
                className="lazyload"
                alt=""
              />
              <noscript>
                &lt;img decoding="async" width="200" height="200"
                src={ArrowGif}
                class="lazyload" alt="" /&gt;
              </noscript>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Banner
import React, { useEffect, useState } from 'react';

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date('2026-12-25T00:00:00'); // تاریخ هدف
    const now = new Date(); // زمان حال

    const difference = targetDate - now; // محاسبه تفاوت زمانی

    let timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // پاک کردن تایمر هنگامUnmount
  }, []);

  return (
    <div className="ggeexx-widget-container">
      <div className="ggeexx-countdown-wrapper">
        <div className="ggeexx-countdown-item">
          <span className="ggeexx-countdown-digits ggeexx-countdown-hours">{timeLeft.days}</span>{" "}
          <span className="ggeexx-countdown-label">روز</span>
        </div>
        <div className="ggeexx-countdown-item">
          <span className="ggeexx-countdown-digits ggeexx-countdown-hours">{timeLeft.hours}</span>{" "}
          <span className="ggeexx-countdown-label">ساعت</span>
        </div>
        <div className="ggeexx-countdown-item">
          <span className="ggeexx-countdown-digits ggeexx-countdown-minutes">{timeLeft.minutes}</span>{" "}
          <span className="ggeexx-countdown-label">دقیقه</span>
        </div>
        <div className="ggeexx-countdown-item">
          <span className="ggeexx-countdown-digits ggeexx-countdown-seconds">{timeLeft.seconds}</span>{" "}
          <span className="ggeexx-countdown-label">ثانیه</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;

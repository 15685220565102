import React , { useState } from 'react'
import Image1 from '../assets/image/arrow-animation.gif'
import ImageURL from '../assets/image/bg-image.jpg'

const UnderBanner = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    const videoElement = document.querySelector('.elementor-video');
    if (videoElement) {
      videoElement.play();
      setIsPlaying(true); // حذف پوستر وقتی ویدیو پخش می‌شود
    }
  };

  return (
    <div
  data-elementor-type="wp-page"
  data-elementor-id={253}
  className="elementor elementor-253"
  data-elementor-post-type="page"
>
  <div
    className="elementor-element elementor-element-a7fff5e e-flex e-con-boxed e-con e-parent"
    data-id="a7fff5e"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-d4b3a1a e-flex e-con-boxed e-con e-child"
        data-id="d4b3a1a"
        data-element_type="container"
      >
        <div className="e-con-inner">
          <div
            className="elementor-element elementor-element-beb2f33 e-con-full e-flex e-con e-child"
            data-id="beb2f33"
            data-element_type="container"
          >
            <div
              className="elementor-element elementor-element-a60136e e-con-full e-flex e-con e-child"
              data-id="a60136e"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-897b377 elementor-widget elementor-widget-image"
                data-id="897b377"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-463c62e elementor-widget elementor-widget-image"
                data-id="463c62e"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-e369a3c elementor-widget elementor-widget-image"
                data-id="e369a3c"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-4a2eb33 elementor-widget elementor-widget-image"
                data-id="4a2eb33"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-4237abd elementor-widget elementor-widget-image"
                data-id="4237abd"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-96fcd92 elementor-widget elementor-widget-image"
                data-id="96fcd92"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-c1db3e5 elementor-widget elementor-widget-image"
                data-id="c1db3e5"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-fbbc7a6 e-con-full e-flex e-con e-child"
              data-id="fbbc7a6"
              data-element_type="container"
              data-settings='{"background_background":"classic","background_motion_fx_motion_fx_mouse":"yes"}'
            >
              <div
                className="elementor-element elementor-element-b6ea67e elementor-widget elementor-widget-heading"
                data-id="b6ea67e"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <span className="elementor-heading-title elementor-size-default">
                    سوالت رو از خودمون میتونی بپرسی!
                  </span>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-f54dbcd elementor-widget elementor-widget-shortcode"
                data-id="f54dbcd"
                data-element_type="widget"
                data-widget_type="shortcode.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-shortcode" />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-4784375 elementor-align-justify elementor-widget elementor-widget-button"
                data-id={4784375}
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      className="elementor-button elementor-button-link elementor-size-sm"
                      href="/tebi-pay/"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-icon">
                          <svg
                            aria-hidden="true"
                            className="e-font-icon-svg e-fas-angle-left"
                            viewBox="0 0 256 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                          </svg>{" "}
                        </span>
                        <span className="elementor-button-text">
                          برای پرداخت درب منزل اینجا کلیک کنید
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-910b518 e-flex e-con-boxed e-con e-parent"
    data-id="910b518"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
    
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-33a9959 e-con-full e-flex e-con e-child"
        data-id="33a9959"
        data-element_type="container"
        data-settings='{"background_background":"classic"}'
      >
        <div
          className="elementor-element elementor-element-38105cb elementor-widget elementor-widget-heading"
          data-id="38105cb"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <span className="elementor-heading-title elementor-size-default">
              مشتریان ما چه می گویند؟
            </span>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-7d54ec4 elementor-widget elementor-widget-video"
          data-id="7d54ec4"
          data-element_type="widget"
          data-settings='{"video_type":"hosted","image_overlay":{"url":"../assets/image/bg-image.jpg","id":745,"size":"","alt":"","source":"library"},"show_image_overlay":"yes","controls":"yes"}'
          data-widget_type="video.default"
        >
          <div className="elementor-widget-container">
          <div className="e-hosted-video elementor-wrapper elementor-open-inline">
      <video
        className="elementor-video"
        src="https://dl.mitnicake.ir/a.mp4" // بررسی کنید که لینک ویدیو صحیح باشد
        controls={isPlaying} // کنترل‌ها تنها وقتی ویدیو پخش می‌شود، ظاهر شوند
        preload="metadata" // بهتر است از metadata استفاده کنید
        controlsList="nodownload"
      />
      {!isPlaying && ( // نمایش پوستر تا زمانی که ویدیو پخش نشده است
        <div
          className="elementor-custom-embed-image-overlay"
          style={{
            backgroundImage: `url(${ImageURL})`,
            cursor: 'pointer'
          }}
          onClick={handlePlayClick} // کلیک برای شروع ویدیو
        >
          <div
            className="elementor-custom-embed-play"
            role="button"
            aria-label="پخش ویدیو"
            tabIndex={0}
          >
            <svg
              aria-hidden="true"
              className="e-font-icon-svg e-eicon-play"
              viewBox="0 0 1000 1000"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M838 162C746 71 633 25 500 25 371 25 258 71 163 162 71 254 25 367 25 500 25 633 71 746 163 837 254 929 367 979 500 979 633 979 746 933 838 837 929 746 975 633 975 500 975 367 929 254 838 162M808 192C892 279 933 379 933 500 933 621 892 725 808 808 725 892 621 938 500 938 379 938 279 896 196 808 113 725 67 621 67 500 67 379 108 279 196 192 279 108 383 62 500 62 621 62 721 108 808 192M438 392V642L642 517 438 392Z"></path>
            </svg>{" "}
            <span className="elementor-screen-only">پخش ویدیو</span>
          </div>
        </div>
      )}
    </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-501a46a e-con-full e-flex e-con e-child"
        data-id="501a46a"
        data-element_type="container"
        data-settings='{"background_background":"classic"}'
      >
        <div
          className="elementor-element elementor-element-0f0bdc3 e-flex e-con-boxed e-con e-child"
          data-id="0f0bdc3"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-5c13561 e-con-full e-flex e-con e-child"
              data-id="5c13561"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-f1dcc85 elementor-widget elementor-widget-image"
                data-id="f1dcc85"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-e9d74b8 e-con-full e-flex e-con e-child"
              data-id="e9d74b8"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-15378db elementor-widget elementor-widget-image"
                data-id="15378db"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-0e2e8f4 e-con-full e-flex e-con e-child"
              data-id="0e2e8f4"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-65ed2f3 elementor-widget elementor-widget-image"
                data-id="65ed2f3"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-312742b e-con-full e-flex e-con e-child"
              data-id="312742b"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-e8c2157 elementor-widget elementor-widget-image"
                data-id="e8c2157"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={200}
                    height={200}
                    src={Image1}
                    className="attachment-large size-large wp-image-260"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-13f66a2 e-flex e-con-boxed e-con e-child"
          data-id="13f66a2"
          data-element_type="container"
          data-settings='{"background_background":"classic"}'
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-5a5dd53 elementor-widget elementor-widget-heading"
              data-id="5a5dd53"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  میدونستی اگه الان آنلاین تهیه کنی
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-09e3976 elementor-widget elementor-widget-heading"
              data-id="09e3976"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  <span style={{ color: "#4ec053" }}>  10 درصد تخفیف </span> 
                   بیشتر میگیری {" "}
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-5760e04 elementor-widget elementor-widget-heading"
              data-id="5760e04"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  + یک پک هدیه ویژه به ارزش
                  <span style={{ color: "#4ec053" }}> 500 هزار تومان </span>
                  هدیه!
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-7e0dcba elementor-widget elementor-widget-heading"
              data-id="7e0dcba"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  هزینه ارسال هم
                  <span style={{ color: "#4ec053" }}> رایگانه </span>
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-cd01e57 e-flex e-con-boxed e-con e-child"
              data-id="cd01e57"
              data-element_type="container"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-207f250 e-con-full e-flex e-con e-child"
                  data-id="207f250"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-8f20567 elementor-widget elementor-widget-heading"
                    data-id="8f20567"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-default">
                        <del>2,200,000 تومان</del>
                      </span>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-a5d33ed elementor-widget elementor-widget-heading"
                    data-id="a5d33ed"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-default">
                        1,980<spen style={{ fontSize: 14 }}>میلیون تومان</spen>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-27b04fd e-con-full e-flex e-con e-child"
                  data-id="27b04fd"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-1d29deb elementor-align-justify elementor-widget elementor-widget-button"
                    data-id="1d29deb"
                    data-element_type="widget"
                    data-widget_type="button.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-button-wrapper">
                        <a
                          className="elementor-button elementor-button-link elementor-size-md"
                          href="/tebi-pay/"
                        >
                          <span className="elementor-button-content-wrapper">
                            <span className="elementor-button-icon">
                              <svg
                                aria-hidden="true"
                                className="e-font-icon-svg e-fas-angle-left"
                                viewBox="0 0 256 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                              </svg>{" "}
                            </span>
                            <span className="elementor-button-text">
                              خرید آنلاین
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default UnderBanner
import React from 'react'

const Footer = () => {
  return (
<div
  className="xclf xclf-5x55 e-flex e-con-boxed e-con e-parent"
  cvc="5x55"
  element-kk="container"
>
  <div className="e-con-inner">
    <div
      className="xclf xclf-3733d3a ggeexx-align-justify ggeexx-widget__width-inherit ggeexx-fixed ggeexx-widget ggeexx-widget-button"
      cvc="3733d3a"
      element-kk="widget"
      sssssss='{"_position":"fixed"}'
      data-widget_type="button.default"
    >
      <div className="ggeexx-widget-container">
        <div className="ggeexx-button-wrapper">
          <a
            className="ggeexx-button ggeexx-button-link ggeexx-size-sm"
            href="https://t.me/Acoachgroup"
            target="_blank"
            rel="nofollow"
          >
            <span className="ggeexx-button-content-wrapper">
              <span className="ggeexx-button-icon">
                <i aria-hidden="true" className="fab fa-telegram" />{" "}
              </span>
              <span className="ggeexx-button-text">
                پشتیبانی مستقیم تلگرام : <a href="https://t.me/09120733042">09120733042</a>
              </span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Footer
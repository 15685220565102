import React from 'react'
import EnamadIco from '../assets/image/3535.png'

const Enamad = () => {
  return (
    <div className="xclf xclf-4x44 e-flex e-con-boxed e-con e-parent" CVC="4x44" element-KK="container">
      <div className="e-con-inner">
        <div className="xclf xclf-b9f70bb ggeexx-widget__width-auto ggeexx-fixed ggeexx-widget ggeexx-widget-image" CVC="b9f70bb" element-KK="widget" sssssss="{&quot;_position&quot;:&quot;fixed&quot;}"
          data-widget_type="image.default">
          <div className="ggeexx-widget-container">
            <a href="namad-etemad/">
              <img decoding="async" width="226" height="136" data-src={EnamadIco} className="lazyload" alt="" /><noscript><img decoding="async" width="226" height="136" data-src={EnamadIco} className="lazyload" alt="" /></noscript>								</a></div>
        </div>
      </div>
    </div>
  )
}

export default Enamad
import React, { useEffect } from 'react';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange'; // اگر به پلاگین تغییرات نیاز دارید
import 'lazysizes/plugins/parent-fit/ls.parent-fit'; // اگر به پلاگین parent-fit نیاز دارید

import {
  Banner,
  Contact,
  Enamad,
  Footer,
  Hero,
  Star,
  Star2,
  Star3,
  Video,
  UnderBanner
} from './components';



function App() {
  return(
    <>
    <Hero />
    <Star />
    <Banner />
    <Star2 />
    <UnderBanner />
    <Star2 />
    <Video />
    <Contact />
    <Enamad />
    <Footer />
    </>
  );
}

export default App;

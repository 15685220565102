import React , {useState} from 'react'
import VideoImage from '../assets/image/hero-image.jpg'



const Hero = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validate = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = 'نام و نام خانوادگی الزامی است.';
    }
    if (!phone) {
      newErrors.phone = 'شماره تلفن الزامی است.';
    } else if (!/^\d+$/.test(phone)) {
      newErrors.phone = 'شماره تلفن باید تنها شامل اعداد باشد.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // برمی‌گرداند true اگر خطایی وجود نداشته باشد
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return; // اگر اعتبارسنجی ناموفق بود، ارسال متوقف می‌شود

    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch('/ez/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          input_1: name,
          input_4: phone,
          is_submit_19: 1,
          gform_submit: 19,
          gform_unique_id: '',
          state_19: 'WyJbXSIsIjY1ODYwZGZlNmU4ZjMzZWQ0ZTExYTM5NDdhMzM1ZmE1Il0=',
          gform_target_page_number_19: 0,
          gform_source_page_number_19: 1,
          gform_field_values: '',
        }),
      });

      if (response.ok) {
        setSuccessMessage('درخواست با موفقیت ارسال شد.');
        setName('');
        setPhone('');
      } else {
        throw new Error('خطا در ارسال درخواست.');
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div
  className="xclf xclf-dba56fb e-flex e-con-boxed e-con e-parent"
  cvc="dba56fb"
  element-kk="container"
  sssssss='{"background_background":"classic"}'
>
  <div className="e-con-inner">
    <div
      className="xclf xclf-4086c3b ggeexx-widget ggeexx-widget-heading"
      cvc="4086c3b"
      element-kk="widget"
      data-widget_type="heading.default"
    >
      <div className="ggeexx-widget-container">
        <span className="ggeexx-heading-title ggeexx-size-default">
          ما اینجا زبان انگلیسی رو واقعی بهت یاد میدیم
        </span>
      </div>
    </div>
    <div
      className="xclf xclf-80dfdc3 ggeexx-widget ggeexx-widget-heading"
      cvc="80dfdc3"
      element-kk="widget"
      data-widget_type="heading.default"
    >
      <div className="ggeexx-widget-container">
        <span className="ggeexx-heading-title ggeexx-size-default">
          آموزش زبان در 59 روز قطعا دروغ هست! ویدیو پایین پخش شده است
        </span>
      </div>
    </div>
    <div
      className="xclf xclf-3e202bf e-con-full e-flex e-con e-child"
      cvc="3e202bf"
      element-kk="container"
    >
      <div
        className="xclf xclf-d1c36ad ggeexx-widget ggeexx-widget-video"
        cvc="d1c36ad"
        element-kk="widget"
        sssssss='{"video_type":"hosted","controls":"yes"}'
        data-widget_type="video.default"
      >
        <div className="ggeexx-widget-container">
        <div className="e-hosted-video ggeexx-wrapper ggeexx-open-inline">
      <video
        className="ggeexx-video"
        src="https://vstudiotv.com/wp-content/uploads/yellow-pencil/1.mp4"
        controls
        preload="metadata" // تغییر preload به metadata
        controlsList="nodownload"
        poster={VideoImage}
        onError={(e) => console.error('Video not found:', e.target.src)} // بررسی خطا
      >
        <track kind="captions" srcLang="fa" />
        Your browser does not support the video tag.
      </video>
    </div>
        </div>
      </div>
    </div>
    <div
      className="xclf xclf-d795747 e-con-full e-flex e-con e-child"
      cvc="d795747"
      element-kk="container"
    >
      <div
        className="xclf xclf-efe69fd e-con-full e-flex e-con e-child"
        cvc="efe69fd"
        element-kk="container"
        sssssss='{"background_background":"classic"}'
      >
        <div
          className="xclf xclf-96458ad ggeexx-widget ggeexx-widget-heading"
          cvc="96458ad"
          element-kk="widget"
          data-widget_type="heading.default"
        >
          <div className="ggeexx-widget-container">
            <h3 className="ggeexx-heading-title ggeexx-size-default">
              اگه میخوایی سریع زبان یاد بگیری بخونش
            </h3>
          </div>
        </div>
        <div
          className="xclf xclf-5233bee ggeexx-widget ggeexx-widget-text-editor"
          cvc="5233bee"
          element-kk="widget"
          data-widget_type="text-editor.default"
        >
          <div className="ggeexx-widget-container">
            <p>
              <span className='colorsigma'>
                <strong>میخوایی زبان یاد بگیری به این چند دلیل:</strong>
              </span>
            </p>
            <p>یا میخوای مهاجرت کنی و برای زندگی دائمی بری کشوری دیگه</p>
            <p>یا میخوایی مهاجرت کاری یا تفریحی داشته باشی به کشوری دیگه</p>
            <p>یا دنبال پیشرفت خودت هستی و میخوایی به این زبان مسلط شی</p>
            <p>یا میخوایی فرزندتون به این زبان جهانی مسلط بشه</p>
            <p>
              ما تموم اینارو میدونیم و قرار هست تو یک محصول خاص دقیق این چالش
              بزرگ زندگی شما رو برطرف کنیم،
            </p>
            <p>
              <span className='color2'>
                فقط کافیه شمارتو بذاری تا خودم بهت زنگ بزنم کاملا راهنمایی کنم
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        className="xclf xclf-803c5ae e-con-full e-flex e-con e-child"
        cvc="803c5ae"
        element-kk="container"
        sssssss='{"background_background":"classic"}'
      >
        <div
          className="xclf xclf-616c44a ggeexx-widget ggeexx-widget-heading"
          cvc="616c44a"
          element-kk="widget"
          data-widget_type="heading.default"
        >
          <div className="ggeexx-widget-container">
            <h3 className="ggeexx-heading-title ggeexx-size-default">
              1150 نفر قبل از شما اینجا شمارشونو گذاشتن و رایگان مشاوره گرفتن
            </h3>
          </div>
        </div>
        <div
          className="xclf xclf-598fb5e ggeexx-widget ggeexx-widget-shortcode"
          cvc="598fb5e"
          element-kk="widget"
          data-widget_type="shortcode.default"
        >
          <div className="ggeexx-widget-container">
            <div className="ggeexx-shortcode">
              <div
                className="gf_browser_chrome gform_wrapper gravity-theme gform-theme--no-framework"
                data-form-theme="gravity-theme"
                data-form-index={0}
                id="gform_wrapper_19"
              >
                <div className="gform_heading">
                  <p className="gform_description" />
                  <p className="gform_required_legend">
                    فیلد های "
                    <span className="gfield_required gfield_required_asterisk">
                      *
                    </span>
                    " اجباری هستند
                  </p>
                </div>
                <form
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      noValidate
    >
      <div className="gform-body gform_body">
        <div className="gform_fields top_label form_sublabel_below description_below validation_below">
          <div className="gfield gfield--type-text gfield--width-half gfield_contains_required">
            <label className="gfield_label gform-field-label" htmlFor="input_19_1">
              نام و نام خانوادگی
              <span className="gfield_required">*</span>
            </label>
            <div className="ginput_container ginput_container_text">
              <input
                name="input_1"
                id="input_19_1"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`large ${errors.name ? 'error' : ''}`}
                aria-required="true"
                aria-invalid={!!errors.name}
                autoComplete="name"
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
          </div>
          <div className="gfield gfield--type-text gfield--width-half gfield_contains_required">
            <label className="gfield_label gform-field-label" htmlFor="input_19_4">
              شماره تلفن
              <span className="gfield_required">*</span>
            </label>
            <div className="ginput_container ginput_container_text">
              <input
                name="input_4"
                id="input_19_4"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className={`large ${errors.phone ? 'error' : ''}`}
                aria-required="true"
                aria-invalid={!!errors.phone}
                autoComplete="tel"
              />
              {errors.phone && <p className="error-message">{errors.phone}</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="gform_footer top_label">
        <input
          type="submit"
          id="gform_submit_button_19"
          className="gform_button button gform-button--width-full"
          value={isSubmitting ? 'در حال ارسال...' : 'درخواست مشاوره + آموزش رایگان'}
          disabled={isSubmitting}
        />
      </div>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Hero
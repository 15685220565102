import React , { useState } from 'react'
import WorldImage from '../assets/image/world-image.png'
import Counter from './Counter'

const Contact = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validate = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = 'نام و نام خانوادگی الزامی است.';
    }
    if (!phone) {
      newErrors.phone = 'شماره تلفن الزامی است.';
    } else if (!/^\d+$/.test(phone)) {
      newErrors.phone = 'شماره تلفن باید تنها شامل اعداد باشد.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // برمی‌گرداند true اگر خطایی وجود نداشته باشد
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return; // اگر اعتبارسنجی ناموفق بود، ارسال متوقف می‌شود

    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch('/ez/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          input_1: name,
          input_3: phone,
          input_19: {
            quantity: 1,
            name: 'نام محصول',
            base_price: '2,770,000 تومان',
          },
          is_submit_20: 1,
          gform_submit: 20,
          gform_unique_id: '',
          state_20: 'WyJ7XCIxOS4xXCI6XCI0ZGUxZWRkYWVjNDg2YmFlYWI3YTNjZmQzMTc2ZmU1NVwiLFwiMTkuMlwiOlwiMmVjNjEyODU1ZTE2MmYzMzAwNDEwZmY3YWQzM2YwNmJcIn0iLCI5MjdmODQ2ZDc1NjRiZWE5OWE3Yzc2ZjIwMTk4MTJjZSJd',
          gform_target_page_number_20: 0,
          gform_source_page_number_20: 1,
          gform_field_values: '',
        }),
      });

      if (response.ok) {
        setSuccessMessage('درخواست با موفقیت ارسال شد.');
        setName('');
        setPhone('');
      } else {
        throw new Error('خطا در ارسال درخواست.');
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
<div
  className="xclf xclf-3x33 e-flex e-con-boxed e-con e-parent"
  cvc="3x33"
  element-kk="container"
  sssssss='{"background_background":"classic"}'
>
  <div className="e-con-inner">
    <div
      className="xclf xclf-725b6fc e-con-full e-flex e-con e-child"
      cvc="725b6fc"
      element-kk="container"
    >
      <div
        className="xclf xclf-4aa64e3 e-con-full e-flex e-con e-child"
        cvc="4aa64e3"
        element-kk="container"
        sssssss='{"background_background":"classic"}'
      >
        <div
          className="xclf xclf-521c4e8 ggeexx-widget ggeexx-widget-heading"
          cvc="521c4e8"
          element-kk="widget"
          data-widget_type="heading.default"
        >
          <div className="ggeexx-widget-container">
            <span className="ggeexx-heading-title ggeexx-size-default">
              یک قدم جلوتری اگر الان آنلاین تهیه کنی
            </span>
          </div>
        </div>
        <div
          className="xclf xclf-2bc0b66 e-con-full e-flex e-con e-child"
          cvc="2bc0b66"
          element-kk="container"
        >
          <div
            className="xclf xclf-eaa85b2 ggeexx-widget ggeexx-widget-heading"
            cvc="eaa85b2"
            element-kk="widget"
            data-widget_type="heading.default"
          >
            <div className="ggeexx-widget-container">
              <h3 className="ggeexx-heading-title ggeexx-size-default">
                5 میلیون تخفیف فقط تا امشب داری:
              </h3>
            </div>
          </div>
          <div
            className="xclf xclf-96f850a ggeexx-widget ggeexx-widget-heading"
            cvc="96f850a"
            element-kk="widget"
            data-widget_type="heading.default"
          >
            <div className="ggeexx-widget-container">
              <span className="ggeexx-heading-title ggeexx-size-default">
                2,770
                <span className='font14px'>هزار تومان</span>
              </span>
            </div>
          </div>
        </div>
        <div
          className="xclf xclf-1b1e9cc ggeexx-countdown--label-block ggeexx-widget ggeexx-widget-countdown"
          cvc="1b1e9cc"
          element-kk="widget"
          data-widget_type="countdown.default"
        >

          <Counter />

        </div>
        <div
          className="xclf xclf-9940c82 ggeexx-widget ggeexx-widget-shortcode"
          cvc="9940c82"
          element-kk="widget"
          id="buy"
          data-widget_type="shortcode.default"
        >
          <div className="ggeexx-widget-container">
            <div className="ggeexx-shortcode">
              <div
                className="gf_browser_chrome gform_wrapper gravity-theme gform-theme--no-framework"
                data-form-theme="gravity-theme"
                data-form-index={0}
                id="gform_wrapper_20"
              >
                <div className="gform_heading">
                  <p className="gform_description" />
                  <p className="gform_required_legend">
                    فیلد های "
                    <span className="gfield_required gfield_required_asterisk">
                      *
                    </span>
                    " اجباری هستند
                  </p>
                </div>
                <form
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      noValidate
      id="gform_20"
      action="/ez/"
      data-formid={20}
    >
      <div className="gform-body gform_body">
        <div className="gform_fields top_label form_sublabel_below description_below validation_below" id="gform_fields_20">
          <div className="gfield gfield--type-text gfield--width-half gfield_contains_required" id="field_20_1">
            <label className="gfield_label gform-field-label" htmlFor="input_20_1">
              نام و نام خانوادگی
              <span className="gfield_required">*</span>
            </label>
            <div className="ginput_container ginput_container_text">
              <input
                name="input_1"
                id="input_20_1"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`large ${errors.name ? 'error' : ''}`}
                aria-required="true"
                aria-invalid={!!errors.name}
                autoComplete="name"
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
          </div>
          <div className="gfield gfield--type-text gfield--width-half gfield_contains_required" id="field_20_3">
            <label className="gfield_label gform-field-label" htmlFor="input_20_3">
              شماره تلفن
              <span className="gfield_required">*</span>
            </label>
            <div className="ginput_container ginput_container_text">
              <input
                name="input_3"
                id="input_20_3"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className={`large ${errors.phone ? 'error' : ''}`}
                aria-required="true"
                aria-invalid={!!errors.phone}
                autoComplete="tel"
              />
              {errors.phone && <p className="error-message">{errors.phone}</p>}
            </div>
          </div>
          <div className="gfield gfield--type-product gfield--input-type-hiddenproduct gfield--width-full gform_hidden gfield_price gfield_price_20_19 gfield_product_20_19 gfield_contains_required" id="field_20_19">
            <label className="gfield_label gform-field-label gfield_label_before_complex" htmlFor="input_20_19_1">
              نام محصول
              <span className="gfield_required">*</span>
            </label>
            <input
              type="hidden"
              name="input_19.3"
              defaultValue={1}
              id="ginput_quantity_20_19"
              className="gform_hidden"
            />
            <input
              type="hidden"
              name="input_19.1"
              defaultValue="نام محصول"
              className="gform_hidden"
            />
            <input
              name="input_19.2"
              id="ginput_base_price_20_19"
              type="hidden"
              defaultValue="2,770,000 تومان"
              className="gform_hidden ginput_amount"
            />
          </div>
        </div>
      </div>
      <div className="gform_footer top_label">
        <input
          type="submit"
          id="gform_submit_button_20"
          className="gform_button button gform-button--width-full"
          value={isSubmitting ? 'در حال ارسال...' : 'پرداخت و ثبت نام'}
          disabled={isSubmitting}
        />
        <input type="hidden" className="gform_hidden" name="is_submit_20" value={1} />
        <input type="hidden" className="gform_hidden" name="gform_submit" value={20} />
        <input type="hidden" className="gform_hidden" name="gform_unique_id" value="" />
        <input type="hidden" className="gform_hidden" name="state_20" value="WyJ7XCIxOS4xXCI6XCI0ZGUxZWRkYWVjNDg2YmFlYWI3YTNjZmQzMTc2ZmU1NVwiLFwiMTkuMlwiOlwiMmVjNjEyODU1ZTE2MmYzMzAwNDEwZmY3YWQzM2YwNmJcIn0iLCI5MjdmODQ2ZDc1NjRiZWE5OWE3Yzc2ZjIwMTk4MTJjZSJd" />
        <input type="hidden" className="gform_hidden" name="gform_target_page_number_20" id="gform_target_page_number_20" value={0} />
        <input type="hidden" className="gform_hidden" name="gform_source_page_number_20" id="gform_source_page_number_20" value={1} />
        <input type="hidden" name="gform_field_values" value="" />
      </div>
      <p className="akismet-fields-container hidden" data-prefix="ak_">
        <label>
          Δ
          <textarea
            name="ak_hp_textarea"
            cols={45}
            rows={8}
            maxLength={100}
            value=""
          />
        </label>
        <input type="hidden" id="ak_js_3" name="ak_js" value={190} />
      </p>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Contact